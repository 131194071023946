<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="field vdatepicker control has-icons-right">
                <v-date-picker
                  class="calendar_input"
                  clearable
                  is-range
                  mode='range'
                  v-model='dateFilter'
                  :input-props='{ placeholder: "Fecha" }'
                  @input="getListByDate"
                />
                <span class="icon is-right">
                  <span class="material-icons text_primary_green">today</span>
                </span>
              </div>
            </div>
            <div class="level-item">
              <div class="select" :class="{ 'is-loading' : loadingCat }">
                <select
                  v-model="categoryId"
                  name="categoryId"
                  @change="getByCategory(categoryId)"
                >
                  <option selected disabled value="">Categoría</option>
                  <option value="">Todos</option>
                  <option v-for="category in categoriesList" :key="category.id" :value="category.id">
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="field control has-icons-right">
                <input
                  class="input input_reset"
                  type="text"
                  placeholder="Buscador"
                  v-model="searchText"
                  @input="searchUser"
                >
                <span class="icon is-right">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div class="columns is-multiline">
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="publicationList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'MainPublications',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  data () {
    return {
      loading: false,
      loadingCat: false,
      page: 1,
      pages: 1,
      dateFilter: {},
      searchText: '',
      categoriesList: [],
      categoryId: '',
      columnsData: [
        {
          id: 'publication_name',
          header: 'Nombre de publicación',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-publicacion', params: { publicationId: row.id } })
        },
        { id: 'created_at', header: 'Fecha de publicación', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'publication_category', header: 'Categoría' },
        { id: 'author', header: 'Autor' },
        { id: 'status', name: 'status', header: 'Estatus' }
      ],
      publicationList: []
    }
  },
  methods: {
    ...mapActions(['getPublications', 'getCategoriesCatalog']),
    async getPublicationsList (page, search, date, category) {
      let startDate = ''
      let endDate = ''
      if (date) {
        startDate = moment(this.dateFilter.start).format('YYYY-MM-DD 00:00:00')
        endDate = moment(this.dateFilter.end).format('YYYY-MM-DD 23:59:59')
      }
      const { publication, total, totalPages } = await this.getPublications({
        page: page,
        limit: DEFAULT_DISPLAY_SIZE,
        search: search,
        startDate: startDate,
        endDate: endDate,
        categoryId: category
      })
      this.total = total
      this.pages = totalPages
      this.publicationList = publication
      this.loading = false
    },
    searchUser ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.loading = true
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push(
          { name: 'publicaciones', query: { page: this.page, search: target.value, category: this.categoryId } }
        ).catch(() => {})
        this.getPublicationsList(this.page, target.value, this.dateFilter, this.categoryId)
      }
    },
    getListByDate () {
      this.loading = true
      this.getPublicationsList(this.page, this.searchText, this.dateFilter)
    },
    getByCategory (category) {
      this.loading = true
      this.categoryId = category
      this.$router.push({ name: 'publicaciones', query: { page: this.page, search: this.searchText, category: this.categoryId } })
      this.getPublicationsList(this.page, this.searchText, this.dateFilter, this.categoryId)
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.$router.push({ name: 'publicaciones', query: { page: this.page, search: this.searchText, category: this.categoryId } })
      this.getPublicationsList(this.page, this.searchText, this.dateFilter, this.categoryId)
    }
  },
  async beforeMount () {
    this.loadingCat = true
    const { success, categories } = await this.getCategoriesCatalog()

    if (success) {
      this.categoriesList = categories
      this.loading = true
      const { page, search, category } = this.$router.history.current.query
      this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
      this.searchText = !search ? this.searchText : search
      this.dateFilter = ''
      this.categoryId = !category ? this.categoryId : category
      this.getPublicationsList(this.page, this.searchText, this.dateFilter, this.categoryId)
    }
    this.loadingCat = false
  }
}
</script>

<style lang="scss" scoped>
  .vdatepicker {
    width: 250px;
  }
  select {
    width: 200px;
  }

  .level-item {
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select {
      width: 100%;
    }
  }
</style>
